@import "../../stylesheets/variables";

.icon-button {
  width: 6.4rem;
  height: 6.4rem;
  background-color: $spotify-green;
  border-radius: 50%;
  border: none;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  &:active {
    background-color: $spotify-dark-green;
  }

  .icon {
    display: inline;
    width: 3.2rem;
    height: 3.2rem;
  }
}

.icon-button.icon-button--secondary {
  display: flex;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  background-color: $spotify-black;

  .icon {
    align-self: center;
    width: 2.4rem;
    height: 2.4rem;
    filter: brightness(0) invert(1);
  }
}

@media screen and (min-width: 768px) {
  .icon-button {
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 100%;

    &__icon {
      width: 4.2rem;
      height: 4.2rem;
    }
  }
}
