@import "../../stylesheets//variables";

.spotify-button {
  height: 4.8rem;
  padding: 0 2.4rem;
  background-color: $spotify-green;
  color: black;
  font-family: $app-font-semibold;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 500px;
  border: none;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  &:active {
    background-color: $spotify-dark-green;
  }

  &--secondary {
    background-color: white;
  }

  &--secondary:active {
    background-color: lightgray;
  }
}

@media screen and (min-width: 768px) {
  .spotify-button {
    font-size: 1.6rem;
  }
}
