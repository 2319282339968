@import "../../stylesheets/variables";

.song-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 10rem;
  min-height: 15rem;
  font-family: $app-font-semibold;

  &__image {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    background-color: $app-grey-dark;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &__name {
    font-family: $app-font-semibold;
    color: white;
    margin-top: 1rem;
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__album {
    max-width: 10rem;
    margin: 0;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    color: $app-grey;
    word-wrap: break-word;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }

  &--selected .song-card__image {
    outline: solid 0.2rem $spotify-green;
  }
}

@media screen and (min-width: 768px) {
  .song-card {
    min-width: 15rem;
    min-height: 20rem;
    font-size: 1.2rem;

    &__image {
      width: 15rem;
      height: 15rem;
    }

    &__album {
      max-width: 15rem;
    }
  }
}

@media screen and (min-width: 2048) {
  .song-card {
    min-width: 18rem;
    min-height: 24rem;
    font-size: 1.6rem;

    &__image {
      width: 18rem;
      height: 18rem;
    }

    &__album {
      max-width: 18rem;
    }
  }
}
