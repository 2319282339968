@import "../../stylesheets/_variables";

.authorization-page {
  padding: $app-main-content-padding-mobile;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  height: calc(
    100% - $app-bar-height-mobile - 2 *
      $app-main-content-padding-mobile
  );
  width: calc(100% - (2 * $app-main-content-padding-mobile));
  background-color: $spotify-black;

  &__title {
    font-family: $app-font-regular;
    font-size: 1.8rem;
    text-align: center;
    color: white;
    margin-bottom: 2.4rem;
  }

  &__subtitle {
    margin-top: 0;
    font-family: $app-font-regular;
    color: white;
    font-style: italic;
    font-weight: 500;
  }

  &__important-message,
  .spotify-button {
    align-self: center;
    min-width: 22rem;
    max-width: 22rem;
  }

  &__important-message {
    color: white;
  }
}

@media screen and (min-width: 768px) {
  .authorization-page {
    padding: $app-main-content-padding;
    height: calc(
      100% - $app-bar-height - 2 * $app-main-content-padding
    );
    width: calc(100% - 2 * $app-main-content-padding);
    &__important-message,
    .spotify-button {
      min-width: 30%;
      max-width: 30%;
    }
  }
}
