@import "../../stylesheets/variables";

.filter-section {
  margin-top: 6rem;
  &__title {
    margin: 0;
    font-family: $app-font-bold;
    font-size: 2rem;
    color: white;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .input-container {
      width: 150px;
      margin-left: auto;
    }

    .audio-feature-value {
      background-color: black;
      font-family: $app-font-bold;
      font-size: 1.6rem;
      color: white;
    }
  }

  &__main {
    margin-top: 1.6rem;
    padding: 0 0.5rem;
    padding-top: 0.5rem;
    display: flex;
    gap: 1.6rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__main--genres {
    margin-top: 2.4rem;
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;

    button {
      font-size: 1.6rem;
      font-family: $app-font-semibold;
      color: white;
      font-style: normal;
      padding: 0;
      background-color: $spotify-black;
      border: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__main--audio-feature {
    margin-top: 1.6rem;
    display: flex;
    justify-content: center;
  }

  &__footer {
    margin-top: 1.6rem;
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 1.6rem;
      font-family: $app-font-semibold;
      color: white;
      font-style: normal;
      padding: 0;
      background-color: $spotify-black;
      border: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .filter-section {
    &__title {
      font-size: 2.4rem;
    }

    &__header {
      .input-container {
        width: 350px;
      }

      .audio-feature-value {
        font-family: $app-font-bold;
        font-size: 2rem;
        color: white;
        margin-left: auto;
      }
    }

    &__footer {
      em {
        font-size: 1.6rem;
      }
    }
  }
}
