@import "../../stylesheets/_variables.scss";

.user-icon {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  background-color: black;
  height: 2.5rem;
  border: none;
  padding-right: 1.6rem;
  border-radius: 500px;

  &__image {
    border-radius: 50%;
    background-color: $app-grey-dark;
    max-width: 2.5rem;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    overflow: hidden;
  }

  &__name {
    font-size: 1.2rem;
    font-family: $app-font-semibold;
    color: white;
    white-space: nowrap;
    overflow: hidden;
  }
}

.user-icon--hidden {
  visibility: hidden;
}

@media screen and (min-width: 768px) {
  .user-icon {
    height: 3.5rem;

    &__image {
      max-width: 3.5rem;
    }

    &__name {
      font-size: 1.6rem;
    }
  }
}
