img {
  width: 100%;
  height: 100%;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 3.2rem;
    height: 3.2rem;
  }
}
