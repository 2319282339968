@import "_variables";

html {
  font-size: 10px;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $spotify-black;
}

html,
body,
#root {
  width: 100%;
}

*::-webkit-scrollbar {
  height: 0.8rem;
  width: 1rem;
}

*::-webkit-scrollbar-thumb {
  background-color: $app-grey;
  border-radius: 500px;
}

@font-face {
  font-family: "Sansation-Bold";
  src: url("../static/fonts/Sansation-Bold.ttf");
}

@font-face {
  font-family: "WorkSans-Bold";
  src: url("../static/fonts/WorkSans-Bold.ttf");
}

@font-face {
  font-family: "WorkSans-Medium";
  src: url("../static/fonts/WorkSans-Medium.ttf");
}

@font-face {
  font-family: "WorkSans-Regular";
  src: url("../static/fonts/WorkSans-Regular.ttf");
}

@font-face {
  font-family: "WorkSans-SemiBold";
  src: url("../static/fonts/WorkSans-SemiBold.ttf");
}
