@import "../../stylesheets/variables";

.app-bar {
  display: flex;
  justify-content: space-between;
  background-color: $spotify-black;
  padding: 0 1.6rem;
  height: $app-bar-height-mobile;
  align-items: center;
}

.app-title {
  font-family: $app-title-font;
  font-size: 2rem;
  color: white;
}

@media screen and (min-width: 768px) {
  .app-bar {
    padding: 0 $app-main-content-padding;
    height: $app-bar-height;
  }

  .app-title {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 2048px) {
  .app-bar {
    padding: 0 $app-main-content-padding-lg-screen;
    height: $app-bar-height;
  }
}
