@import "../../stylesheets/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.67);
}

.dialog {
  position: relative;
  max-height: 80vh;
  max-width: 70vw;
  padding: 2.4rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $app-font-regular;
  margin: 0 2.4rem;
  overflow: auto;
  background-color: $app-grey-dark;

  &__header {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
    color: white;
  }

  &__main-content {
    font-size: 1.2rem;
    margin-bottom: 2.4rem;
  }
}

@media screen and (min-width: 768px) {
  .dialog {
    &__header {
      font-size: 2rem;
    }

    &__main-content {
      font-size: 1.6rem;
    }
  }
}
