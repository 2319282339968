// Layout
$app-bar-height-mobile: 4.8rem;
$app-bar-height: 6.4rem;
$app-main-content-padding-mobile: 1.6rem;
$app-main-content-padding: 20rem;
$app-main-content-padding-lg-screen: 40rem;

// Colors
$spotify-black: #191414;
$spotify-green: #1db954;
$spotify-dark-green: #169c46;
$app-grey: #b0b0b0;
$app-grey-dark: rgb(39, 34, 34);

// Fonts
$app-title-font: "Sansation-Bold";
$app-font-bold: "WorkSans-Bold";
$app-font-semibold: "WorkSans-SemiBold";
$app-font-medium: "WorkSans-Medium";
$app-font-regular: "WorkSans-Regular";
