@import "../../stylesheets/variables";

.filter-page {
  position: relative;
  padding: 0 $app-main-content-padding-mobile;

  .filter-section:last-of-type {
    margin-bottom: 10rem;
  }
}

.ok-icon-button {
  position: fixed;
  bottom: 2rem;
  right: 1.6rem;
}

@media screen and (min-width: 768px) {
  .filter-page {
    padding: 0 $app-main-content-padding;
  }

  .ok-icon-button {
    right: 20rem;
  }
}

@media screen and (min-width: 2048px) {
  .filter-page {
    padding: 0 $app-main-content-padding-lg-screen;
  }

  .ok-icon-button {
    right: 40rem;
  }
}
