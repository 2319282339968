@import "../../stylesheets/variables";

.artist-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  min-width: 10rem;
  min-height: 15rem;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }

  &--selected .artist-card__image {
    outline: solid 0.2rem $spotify-green;
  }

  &__image {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    border-radius: 50%;
    background-color: $app-grey-dark;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &__name {
    font-family: $app-font-semibold;
    font-size: 1.2rem;
    color: white;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media screen and (min-width: 768px) {
  .artist-card {
    min-width: 15rem;
    min-height: 20rem;

    &__image {
      width: 15rem;
      height: 15rem;
    }

    &__name {
      font-size: 1.4rem;
    }
  }
}

@media screen and (min-width: 2048px) {
  .artist-card {
    min-width: 18rem;
    min-height: 20rem;

    &__image {
      width: 18rem;
      height: 18rem;
      aspect-ratio: 1 / 1;
    }

    &__name {
      font-size: 1.6rem;
    }
  }
}
