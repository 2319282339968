@import "../../stylesheets/variables";

.recommendations-page {
  margin-top: 6rem;
  margin-bottom: 10rem;
  padding: 0 $app-main-content-padding-mobile;

  &__title {
    margin-top: 2rem;
    font-family: $app-font-bold;
    font-size: 2.4rem;
    color: white;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 2.4rem;

    .song-card,
    .song-card > .song-card__image {
      min-width: 100%;
    }
  }
}

.filter-icon-button {
  position: fixed;
  bottom: 2rem;
  right: 1.6rem;
}

@media screen and (min-width: 768px) {
  .recommendations-page {
    font-size: 2.4rem;
    padding: 0 $app-main-content-padding;

    &__content {
      grid-template-columns: repeat(
        auto-fit,
        minmax(150px, 1fr)
      );
    }
  }

  .filter-icon-button {
    right: 20rem;
  }
}

@media screen and (min-width: 2048px) {
  .ok-icon-button {
    right: 40rem;
  }
}
